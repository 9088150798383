import React from "react"
import { server } from "../../../server"

export function VerifyEmailPage() {
    return (
        <div style={{ margin: 32 }}>
            <img
                src={`${server()}/static/sayfr-small.svg`}
                style={{
                    height: 38,
                    width: 38,
                }}
            />
            <h2>Email verification required</h2>
            <div>Please check your email and click the link to verify your email address.</div>
            <div>Can't see the email? Remember to check your junk mail folder.</div>
            <div style={{ marginTop: 32 }}>
                <a href="/modules">When email is verified, click here to continue</a>
            </div>
        </div>
    )
}
