import React, { CSSProperties } from "react"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { useHover } from "../../../packages/hooks/useHover"
import { server } from "../../../server"
import { darkMustard, incorrectRed } from "./colors"
import { logout } from "../../../packages/ssr/Login"
import { GetModulesUserDto } from "./client"
import { ColorStyles } from "../../../packages/ui"

export function MenuBar(props: {
    direction: "vertical" | "horizontal"
    user: GetModulesUserDto
    disableDashboard?: boolean
    disableLeaderboards?: boolean
    children: React.ReactNode
}) {
    const navigate = useNavigate()
    const vertical = props.direction === "vertical"
    const url = new URL(window.location.href)
    return (
        <div
            style={{
                display: "flex",
                flexDirection: vertical ? "row" : "column",
                height: "100%",
                backgroundColor: "white",
            }}
        >
            <div
                style={{
                    width: vertical ? 64 : undefined,
                    height: vertical ? undefined : 64,
                    display: "flex",
                    flexDirection: vertical ? "column" : "row",
                    alignItems: "center",
                    borderBottom: vertical ? undefined : "1px solid #EAECF0",
                    borderRight: vertical ? "1px solid #EAECF0" : undefined,
                }}
            >
                <img
                    src={`${server()}/static/sayfr-small.svg`}
                    style={{
                        height: 32,
                        width: 32,
                        marginLeft: vertical ? undefined : 24,
                        marginTop: vertical ? 24 : undefined,
                    }}
                />
                {!vertical && (
                    <div
                        style={{ fontSize: 20, fontWeight: "bold", marginLeft: 16, color: "#444" }}
                    >
                        SAYFR
                    </div>
                )}
                {vertical && <div style={{ marginTop: 16 }} />}

                {!props.disableDashboard && (
                    <MenuButton onClick={() => navigate("/")} active={url.pathname === "/"}>
                        <HomeIcon color={url.pathname === "/" ? darkMustard : undefined} />
                    </MenuButton>
                )}

                <MenuButton
                    onClick={() => navigate("/modules")}
                    active={url.pathname === "/modules"}
                >
                    <PlayIcon color={url.pathname === "/modules" ? darkMustard : undefined} />
                </MenuButton>

                {!props.disableLeaderboards && (
                    <MenuButton
                        onClick={() => navigate("/scores")}
                        active={url.pathname === "/scores"}
                    >
                        <ScoresIcon color={url.pathname === "/scores" ? darkMustard : undefined} />
                    </MenuButton>
                )}

                <div style={{ flex: 1 }} />
                <div>
                    <div
                        onClick={() => navigate("/profile")}
                        style={{
                            cursor: "pointer",
                            width: 48,
                            height: 48,
                            borderRadius: 24,
                            backgroundColor: "#FAF3E7",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: vertical ? undefined : 16,
                        }}
                    >
                        <ProfileIcon />
                    </div>
                </div>
                {!vertical && (
                    <div>
                        <div>{props.user.name ?? props.user.email}</div>
                        <div style={{ fontSize: 14, color: "#888" }}>{props.user.company}</div>
                    </div>
                )}
                <MenuButtonFontIcon
                    icon="sign-out"
                    tooltip="Log out"
                    color={incorrectRed}
                    onClick={() => logout("/")}
                />
            </div>
            {props.children}
        </div>
    )
}

function MenuButtonFontIcon(props: {
    icon: string
    onClick: () => void
    tooltip?: string
    color?: string
}) {
    return (
        <MenuButton
            onClick={props.onClick}
            tooltip={props.tooltip}
            style={{ margin: 16, color: props.color }}
        >
            <i className={`fas fa-${props.icon}`} />
        </MenuButton>
    )
}

function MenuButton(props: {
    children: JSX.Element
    onClick: () => void
    tooltip?: string
    style?: CSSProperties
    active?: boolean
}) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            onClick={props.onClick}
            title={props.tooltip}
            style={{
                padding: 16,
                cursor: "pointer",
                color: hover ? darkMustard : ColorStyles.gray[700],
                borderRadius: 8,
                backgroundColor: props.active
                    ? "#FAF3E7"
                    : hover
                      ? ColorStyles.gray[200]
                      : undefined,
                ...props.style,
            }}
        >
            {props.children}
        </div>
    )
}

function HomeIcon(props: { color?: string }) {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.705 7.97574C20.0557 8.27436 20.1008 8.79866 19.8022 9.14936C19.5036 9.50006 18.9793 9.5452 18.6286 9.24658L17.7779 8.52436V15.0001C17.7779 16.5348 16.5348 17.7779 15.0001 17.7779H5.00003C3.46599 17.7779 2.22223 16.5348 2.22223 15.0001V8.52436L1.37258 9.24658C1.02153 9.5452 0.495835 9.50006 0.197916 9.14936C-0.0998285 8.79866 -0.0567379 8.27436 0.294202 7.97574L9.46186 0.197918C9.77089 -0.0659726 10.2292 -0.0659726 10.5383 0.197918L19.705 7.97574ZM5.00003 16.1112H6.66671V10.8334C6.66671 10.066 7.28824 9.4445 8.0556 9.4445H11.9445C12.7119 9.4445 13.3334 10.066 13.3334 10.8334V16.1112H15.0001C15.6147 16.1112 16.1112 15.6147 16.1112 15.0001V7.11115L10.0001 1.92605L3.88891 7.11115V15.0001C3.88891 15.6147 4.38544 16.1112 5.00003 16.1112ZM8.33338 16.1112H11.6667V11.1112H8.33338V16.1112Z"
                fill={props.color ?? "#545656"}
            />
        </svg>
    )
}

function PlayIcon(props: { color?: string }) {
    return (
        <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.09482 0.27375C1.77083 -0.105331 2.59909 -0.0897031 3.26081 0.314382L16.1187 8.17104C16.7572 8.56396 17.1457 9.25604 17.1457 10.0017C17.1457 10.7474 16.7572 11.4394 16.1187 11.7922L3.26081 19.6506C2.59909 20.0927 1.77083 20.1105 1.09482 19.731C0.418686 19.3515 0 18.6371 0 17.8602V2.14326C0 1.36813 0.418686 0.653277 1.09482 0.27375ZM2.14321 17.8602L15.0025 10.0017L2.14321 2.14326V17.8602Z"
                fill={props.color ?? "#545656"}
            />
        </svg>
    )
}

function ScoresIcon(props: { color?: string }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.9986 0C10.3472 0 10.6654 0.198911 10.817 0.512243L13.4161 5.86503L19.2243 6.72508C19.5653 6.77433 19.8456 7.01303 19.9555 7.33886C20.0616 7.66848 19.9744 8.02842 19.732 8.26711L15.5188 12.4423L16.5153 18.3339C16.5721 18.6749 16.4319 19.0234 16.1478 19.2243C15.8674 19.4251 15.462 19.4516 15.1892 19.2887L9.9986 16.5153L4.77766 19.2887C4.50487 19.4516 4.13357 19.4251 3.84941 19.2243C3.56904 19.0234 3.42886 18.6749 3.45159 18.3339L4.47835 12.4423L0.269396 8.26711C0.0246413 8.02842 -0.0621217 7.66848 0.0454796 7.33886C0.152702 7.01303 0.435724 6.77433 0.776335 6.72508L6.58112 5.86503L9.18022 0.512243C9.33556 0.198873 9.65003 0 9.9986 0ZM9.9986 2.99124L8.00949 7.09259C7.87688 7.36159 7.61924 7.55103 7.32372 7.5965L2.84084 8.25574L6.09616 11.48C6.30454 11.6884 6.39926 11.9877 6.35 12.2794L5.58467 16.8108L9.57047 14.6815C9.83947 14.5375 10.1615 14.5375 10.4267 14.6815L14.4125 16.8108L13.6472 12.2794C13.5979 11.9877 13.6964 11.6884 13.9048 11.48L17.1594 8.25574L12.6773 7.5965C12.3779 7.55103 12.1203 7.36159 11.9915 7.09259L9.9986 2.99124Z"
                fill={props.color ?? "#545656"}
            />
        </svg>
    )
}

function ProfileIcon() {
    return (
        <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 11.5C11.5155 11.5 12.969 10.8942 14.0406 9.81586C15.1122 8.73753 15.7143 7.27499 15.7143 5.75C15.7143 4.22501 15.1122 2.76247 14.0406 1.68414C12.969 0.605802 11.5155 0 10 0C8.48448 0 7.03103 0.605802 5.95939 1.68414C4.88775 2.76247 4.28571 4.22501 4.28571 5.75C4.28571 7.27499 4.88775 8.73753 5.95939 9.81586C7.03103 10.8942 8.48448 11.5 10 11.5ZM7.95982 13.6562C3.5625 13.6562 0 17.241 0 21.6658C0 22.4025 0.59375 23 1.32589 23H18.6741C19.4062 23 20 22.4025 20 21.6658C20 17.241 16.4375 13.6562 12.0402 13.6562H7.95982Z"
                fill="#F8B031"
            />
        </svg>
    )
}
